<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-05">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">웨비나 라이브</h1>
        <div class="feature-body">
          <p>BMS에서 주최하는 질환별, 제품별 웨비나 라이브 일정을 조회하고, 사전 등록하실 수 있는 서비스입니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb">웨비나 & VOD</li>
            <li class="breadcrumb">웨비나 라이브</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <div class="section" id="print-area">
        <div class="view_top">
          <div class="view_top_left">
            <h1>{{ title }}</h1>
            <p class="date">{{ date }}</p>
            <shared-area
                file-name="BMSON-웨비나라이브"/>
            <a>
              <button type="button" :class="`btn_${linkType}`" @click="postWebinar">{{
                  linkTypeName[linkType]
                }}
              </button>
            </a>
          </div>
          <div class="view_top_right">
            <img :src="thumbnail" alt="">
          </div>
        </div>


        <div style="padding:56.25% 0 0 0;position:relative;" class="ignore-pdf" v-if="webinarVodUrl">
          <iframe
              :src="webinarVodUrl"
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
              style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
        </div>

        <div class="webinar_view_body" v-html="content"/>

        <div class="related_contents" v-if="Array.isArray(relatedContents) && relatedContents.length !== 0">
          <h1>Related Contents</h1>
          <ul>
            <li v-for="(relatedContent, index) in relatedContents" :key="index">
              <a :href="relatedContent.redirectUrl">
                <img :src="relatedContent.thumbnailUrl" :alt="relatedContent.title">
                <p>{{ relatedContent.title }}</p>
              </a>
            </li>
          </ul>
        </div>


        <!--이전 보기 버튼-->
        <div class="btn_area_right btn_prev ignore-pdf">
          <button @click="$router.go(-1)">이전 보기</button>
        </div>


        <!--        <div class="modal" v-if="showPostWebinar">-->

        <!--          &lt;!&ndash; 파이프라인 사이트 이탈 Modal의 내용 &ndash;&gt;-->
        <!--          <div class="modal_contents">-->
        <!--            <div class="modal_close close">-->
        <!--              <a @click="closeModal">close</a>-->
        <!--            </div>-->
        <!--            <p class="modal_tit">현재 보고 계신<br> <strong>BMSON</strong> 웹사이트를 나갑니다.</p>-->
        <!--            <p class="modal_exp">현재 웹사이트를 나와 다른 외부 웹사이트와 연결됩니다. BMSON은 외부 사이트의 콘텐츠에 대한 책임을 지지 않으며, 해당 콘텐츠로 인한 손해나 손상에-->
        <!--              대한 법적 책임이 없습니다. 외부 사이트 링크는 단지 웹사이트 사용자들에게 편의를 제공하기 위함입니다.</p>-->
        <!--            <div class="modal_btn">-->
        <!--              <form @submit="closeModal" :action="linkType === 'replay'? webinarVodUrl : webinarUrl"-->
        <!--                    method="post" target="_blank">-->
        <!--                <input type="hidden" name="member" :value="encodeMember">-->
        <!--                <button type="submit"><span class="btn_modal">계속하기</span></button>-->
        <!--              </form>-->

        <!--              <span class="btn_modal" @click="closeModal">취소</span>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="modal" v-if="showPostWebinar">

          <!-- 파이프라인 사이트 이탈 Modal의 내용 -->
          <div class="modal_contents">
            <div class="modal_close close">
              <a @click="closeModal">close</a>
            </div>
            <p class="modal_tit">현재 보고 계신<br> <strong>BMSON</strong> 웹사이트를 나갑니다.</p>
            <p class="modal_exp">현재 웹사이트를 나와 다른 외부 웹사이트와 연결됩니다. BMSON은 외부 사이트의 콘텐츠에 대한 책임을 지지 않으며, 해당 콘텐츠로 인한 손해나 손상에
              대한 법적 책임이 없습니다. 외부 사이트 링크는 단지 웹사이트 사용자들에게 편의를 제공하기 위함입니다.</p>
            <div class="modal_btn">
              <form @submit="closeModal" :action="linkType === 'replay'? webinarVodUrl : webinarUrl"
                    method="post" target="_blank" id="form_in_modal">
                <input type="hidden" name="member" :value="encodeMember">
                <!--                <button type="submit"></button>-->
              </form>
              <span class="btn_modal" @click="submitModal">계속하기</span>
              <span class="btn_modal" @click="closeModal">취소</span>
            </div>
          </div>
        </div>

        <!--  Modal -->
        <div class="modal" v-if="showSubmitModal">

          <!--  Modal의 내용 -->
          <div class="modal_contents">
            <div class="modal_close close">
              <a @click="closeModal">close</a>
            </div>
            <p class="modal_tit">감사합니다!</p>
            <div class="modal_exp">
              <ul>
                <li>강의명 : {{ modalTitle }}</li>
                <li>강사명 : {{ modalProf }}</li>
                <li>강의일시 : {{ modalDate }}</li>
              </ul>
              <span>상기 웨비나 라이브 <b>사전등록</b>이 완료되었습니다.</span>
              <p>* 웨비나 라이브 1일전에 해당 웨비나 안내 메일을 수신하실 수 있습니다.</p>
              <p>* 본 사전등록 내역은 본 웹사이트의 Mypage에서도 확인하 실 수 있습니다.</p>
            </div>
            <div class="modal_btn">
              <span class="btn_modal" @click="closeModal">확인</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </main>
</template>

<script>
import SharedArea from "@/components/common/SharedArea";
import webinarAPI from "@/api/webinarAPI";

export default {
  name: "WebinarLiveView",
  components: {SharedArea},
  created() {
    this.fetchWebinar()
  },
  data() {
    return {
      title: '',
      date: '',
      link: '',
      linkType: '',
      prof: '',
      thumbnail: '',
      linkTypeName: {
        'fini': '웨비나 종료',
        'join': '참여하기',
        'comp': '등록완료',
        'regi': '사전등록',
        'replay': '다시보기',
      },
      content: '',
      relatedContents: [],
      webinarUrl: '',
      webinarVodUrl: '',
      encodeMember: '',
      showSubmitModal: false,
      showPostWebinar: false,
      modalTitle: '',
      modalProf: '',
      modalDate: '',
    }
  },
  mounted() {
    if (this.$route.query['open-modal']) {
      this.$nextTick(() => {
        this.switchModal()
      })
    }
  },
  methods: {
    switchModal() {
      this.showSubmitModal = !this.showSubmitModal;
      if (this.showSubmitModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    closeModal() {
      setTimeout(() => {
        document.documentElement.classList.remove("overflow-y-hidden");
        this.showPostWebinar = false;
        this.showSubmitModal = false;
      })
      return true
    },
    submitModal() {
      document.getElementById('form_in_modal').submit();
      this.closeModal()
      return false;
    },
    postWebinar() {
      if (this.linkType === 'regi') {
        webinarAPI.postWebinarRegister({
          id: this.link,
          title: this.title,
          prof: this.prof,
          date: this.date,
        }.id).then(value => {
          const {data} = value
          if (data.code === 200) {
            this.modalTitle = this.title;
            this.modalProf = this.prof;
            this.modalDate = this.date;
            this.showSubmitModal = true
            document.documentElement.classList.add("overflow-y-hidden");
            this.reload()
          }
        }).catch(e => {
          console.log(e)
          throw e
        })
      } else if (this.linkType === 'replay') {
        return false;
      } else if (this.linkType === 'join') {
        webinarAPI.postWebinarUserInfo().then(result => {
          let {data: {value}} = result
          webinarAPI.savePointWebinar({
            id: this.link
          }).then()
          this.encodeMember = value
          this.showPostWebinar = true

          document.documentElement.classList.add("overflow-y-hidden");
        })
      }
    },
    fetchWebinar() {
      this.$store.dispatch('fetchWebinar', {id: this.$route.params.webinarLiveNo})
          .then(value => {
            let {
              title,
              date,
              link,
              linkType,
              prof,
              thumbnail,
              content,
              relatedContents,
              webinarUrl,
              webinarVodUrl
            } = value
            this.title = title
            this.date = date
            this.link = link
            this.linkType = linkType
            this.prof = prof
            this.thumbnail = thumbnail
            this.content = content
            this.relatedContents = relatedContents
            this.webinarUrl = webinarUrl
            this.webinarVodUrl = webinarVodUrl
          })
    },
  },
}
</script>

<style scoped>
.webinar_view_body {
  margin: 0 0 50px 0;
}

.webinar_view_body ::v-deep img {
  width: 100%;
}
</style>
